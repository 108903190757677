"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDateWithTimezone = exports.parseUtcDateObj = exports.parseUtcToTimestamp = exports.transformToICalKey = exports.foldLine = void 0;
var luxon_1 = require("luxon");
var common_1 = require("../common");
var constants_1 = require("../constants");
var utils_1 = require("./utils");
var CALENDAR_BEGIN = 'BEGIN:VCALENDAR\n';
var CALENDAR_END = 'END:VCALENDAR';
var foldLine = function (row) {
    var result = '';
    var foldCount = row.length / common_1.MAX_LINE_LENGTH;
    if (row.length < common_1.MAX_LINE_LENGTH) {
        return row;
    }
    var tempRow = row;
    for (var i = 1; i <= foldCount + 1; i += 1) {
        if (tempRow.length <= common_1.MAX_LINE_LENGTH) {
            result = result + tempRow;
            return result;
        }
        else {
            result = result + tempRow.slice(0, common_1.MAX_LINE_LENGTH) + '\n ';
            var newTempRow = tempRow.slice(i * common_1.MAX_LINE_LENGTH);
            if (!newTempRow) {
                tempRow = tempRow.slice(common_1.MAX_LINE_LENGTH);
            }
            else {
                tempRow = tempRow.slice(i * common_1.MAX_LINE_LENGTH);
            }
        }
    }
    return result;
};
exports.foldLine = foldLine;
var addKeyValue = function (prevData, key, value) {
    return "".concat(prevData).concat(key).concat(value, "\n");
};
var transformToICalKey = function (key) {
    var result = '';
    for (var i = 0; i < key.length; i += 1) {
        var letter = key[i];
        // Transform camel case to dash
        if (letter.toUpperCase() === letter) {
            result += "-".concat(letter);
        }
        else {
            result += letter.toUpperCase();
        }
    }
    return result;
};
exports.transformToICalKey = transformToICalKey;
var mapObjToString = function (obj) {
    var result = '';
    var mailtoValue = ':mailto:';
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (key !== 'mailto') {
            result = result + key.toUpperCase() + '=' + value + ';';
        }
        else {
            mailtoValue = mailtoValue + value;
        }
    }
    var endingNeedsSlice = result.slice(result.length - 1, result.length) === ';';
    return ((endingNeedsSlice ? result.slice(0, result.length - 1) : result) +
        mailtoValue);
};
var removeDot = function (date) {
    var indexOfDot = date.indexOf('.');
    var indexOfZ = date.indexOf('Z');
    if (indexOfDot === -1) {
        return date;
    }
    return date.slice(0, indexOfDot);
};
var removeZ = function (date) {
    var indexOfZ = date.indexOf('Z');
    if (indexOfZ === -1) {
        return date;
    }
    return date.slice(0, indexOfZ);
};
var addZ = function (date) {
    var indexOfZ = date.indexOf('Z');
    if (indexOfZ !== -1) {
        return date;
    }
    return date + 'Z';
};
var parseSimpleDate = function (date) {
    var result = removeDot(date.replace('-', ''));
    return addZ(result);
};
var parseUtcToTimestamp = function (utcDate) {
    var result = '';
    for (var i = 0; i < utcDate.length; i += 1) {
        var letter = utcDate[i];
        if (i === utcDate.length - 1 && letter === 'Z') {
            return addZ(removeDot(result));
        }
        if (letter !== ':' && letter !== '-') {
            result += letter;
        }
    }
    result = removeDot(result);
    return result;
};
exports.parseUtcToTimestamp = parseUtcToTimestamp;
var parseUtcDateObj = function (utcDate) {
    return addZ((0, exports.parseUtcToTimestamp)(utcDate.value));
};
exports.parseUtcDateObj = parseUtcDateObj;
var parseDateWithTimezone = function (dateObj) {
    var adjustedDateTimeRaw = luxon_1.DateTime.fromISO(dateObj.value, {
        zone: dateObj.timezone,
    });
    if (adjustedDateTimeRaw.invalidReason === 'unsupported zone') {
        throw Error("".concat(adjustedDateTimeRaw === null || adjustedDateTimeRaw === void 0 ? void 0 : adjustedDateTimeRaw.invalidReason, " ").concat(adjustedDateTimeRaw));
    }
    var adjustedDateTime = adjustedDateTimeRaw.toString();
    var formatFromUtc = removeZ((0, exports.parseUtcToTimestamp)(adjustedDateTime));
    return "TZID=".concat(dateObj.timezone, ":").concat(adjustedDateTimeRaw.toFormat("yyyyMMdd'T'HHmmss"));
};
exports.parseDateWithTimezone = parseDateWithTimezone;
var buildString = function (event, prevResult) {
    var result = prevResult;
    // Build event string from object props
    for (var _i = 0, _a = Object.entries(event); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        var keyString = key;
        var valueAny = value;
        // Rules
        var isValueArray = Array.isArray(valueAny);
        var delimiter = isValueArray ? ';' : ':';
        var isDateKey = (0, common_1.checkIfIsDateKeyToString)(key);
        var isAttendeeKey = key === constants_1.ATTENDEE_KEY;
        var isOrganizerKey = key === constants_1.ORGANIZER_KEY;
        var isAlarmsKey = key === constants_1.ALARMS_KEY;
        var isExDateKey = key === constants_1.EXDATE_KEY;
        // Different rules for dates
        if (isExDateKey) {
            result += (0, utils_1.formatExDatesToString)(valueAny);
        }
        else if (isDateKey) {
            var hasTimezone = valueAny.timezone;
            var isSimpleObj = !hasTimezone && valueAny.value;
            var isSimpleDate = !hasTimezone &&
                isSimpleObj &&
                valueAny.value.length === common_1.DATE_ONLY_LENGTH;
            if (isSimpleDate) {
                // Date only for all day events
                result +=
                    (0, exports.foldLine)("".concat((0, exports.transformToICalKey)(key), ";VALUE=DATE:").concat(valueAny.value)) +
                        '\n';
            }
            else if (isSimpleObj) {
                result +=
                    (0, exports.foldLine)("".concat((0, exports.transformToICalKey)(key)).concat(delimiter).concat((0, exports.parseUtcDateObj)(valueAny))) + '\n';
            }
            else if (hasTimezone) {
                delimiter = ';';
                // Object with timezone and value
                result +=
                    (0, exports.foldLine)("".concat((0, exports.transformToICalKey)(key)).concat(delimiter).concat((0, exports.parseDateWithTimezone)(valueAny))) + '\n';
            }
            else {
                result +=
                    (0, exports.foldLine)("".concat((0, exports.transformToICalKey)(key)).concat(delimiter).concat((0, exports.parseUtcToTimestamp)(valueAny))) + '\n';
            }
        }
        else if (isAttendeeKey) {
            for (var _c = 0, valueAny_1 = valueAny; _c < valueAny_1.length; _c++) {
                var item = valueAny_1[_c];
                result += (0, exports.foldLine)('ATTENDEE;' + mapObjToString(item)) + '\n';
            }
        }
        else if (isOrganizerKey) {
            result += (0, exports.foldLine)('ORGANIZER;' + mapObjToString(valueAny)) + '\n';
        }
        else if (isAlarmsKey) {
            result += (0, utils_1.formatAlarmsToString)(valueAny);
        }
        else {
            result +=
                (0, exports.foldLine)("".concat((0, exports.transformToICalKey)(key)).concat(delimiter).concat(valueAny)) + '\n';
        }
    }
    return result;
};
/**
 * Build iCal string
 * @param iCalObj
 */
var toString = function (iCalObj) {
    var calendar = iCalObj.calendar, events = iCalObj.events, todos = iCalObj.todos;
    var prodid = calendar.prodid, version = calendar.version, calscale = calendar.calscale, method = calendar.method;
    var result = '';
    // Add calendar info
    result += CALENDAR_BEGIN;
    // Add prodid
    result = addKeyValue(result, 'PRODID:', prodid);
    // Add version
    result = addKeyValue(result, 'VERSION:', version);
    if (method) {
        result = addKeyValue(result, 'METHOD:', method);
    }
    if (calscale) {
        result = addKeyValue(result, 'CALSCALE:', calscale);
    }
    // Loop over all events
    if (events && events.length > 0) {
        for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
            var event_1 = events_1[_i];
            result = buildString(event_1, result);
        }
    }
    if (todos && todos.length > 0) {
        for (var _a = 0, todos_1 = todos; _a < todos_1.length; _a++) {
            var todo = todos_1[_a];
            result = buildString(todo, result);
        }
    }
    result += CALENDAR_END;
    return result;
};
exports.default = toString;
