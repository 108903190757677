"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatStringToKeyValueObj = void 0;
var common_1 = require("../common");
var dateHelpers_1 = require("./dateHelpers");
var constants_1 = require("../constants");
var formatHelpers_1 = require("./formatHelpers");
var validator_1 = require("./validator");
var utils_1 = require("./utils");
var utils_2 = require("../toString/utils");
/**
 * Extract only calendar string part
 * @param iCalString
 */
var getVCalendarString = function (iCalString) {
    var firstItemIndex = iCalString.indexOf(constants_1.EVENT_BEGIN_KEY_VALUE);
    if (firstItemIndex === -1) {
        firstItemIndex = iCalString.indexOf(constants_1.TODO_BEGIN_KEY_VALUE);
    }
    return iCalString.slice(0, firstItemIndex);
};
/**
 * Parse calendar string to calendar JSON
 * @param calendarString
 */
var formatVCalendarStringToObject = function (calendarString) {
    var calendarRows = (0, formatHelpers_1.splitRowsToArray)(calendarString);
    var result = {};
    for (var _i = 0, calendarRows_1 = calendarRows; _i < calendarRows_1.length; _i++) {
        var row = calendarRows_1[_i];
        var keyValue = splitRowToKeyValueObj(row);
        var key = keyValue.key, value = keyValue.value;
        if (key !== '' && value !== '') {
            result[key] = value;
        }
    }
    return result;
};
/**
 * Split string events to array
 * @param iCalEvents
 * @param key
 */
var splitStringEvents = function (iCalEvents, key) {
    if (key === void 0) { key = constants_1.EVENT_BEGIN_KEY_VALUE; }
    // Get array of events
    var result = iCalEvents.split(key).slice(1);
    if (!result) {
        return '';
    }
    // Add missing delimiter from split to each record
    result = result.map(function (item) { return "".concat(key).concat(item); });
    return result;
};
var formatStringToKeyValueObj = function (stringValue, eventObj) {
    var eventWithMergedRows = (0, formatHelpers_1.splitRowsToArray)(stringValue);
    for (var _i = 0, eventWithMergedRows_1 = eventWithMergedRows; _i < eventWithMergedRows_1.length; _i++) {
        var stringEvent = eventWithMergedRows_1[_i];
        var keyValue = splitRowToKeyValueObj(stringEvent);
        var key = keyValue.key, value = keyValue.value;
        // Handle nested array value so it does not override with same key like ATTENDEE
        if (key === constants_1.ATTENDEE_KEY) {
            eventObj[constants_1.ATTENDEE_KEY] = Array.isArray(eventObj[constants_1.ATTENDEE_KEY])
                ? __spreadArray(__spreadArray([], eventObj[constants_1.ATTENDEE_KEY], true), [value], false) : [value];
        }
        else if (key === constants_1.EXDATE_KEY) {
            if (Array.isArray(value)) {
                eventObj[constants_1.EXDATE_KEY] = __spreadArray([], value, true);
            }
            else {
                eventObj[constants_1.EXDATE_KEY] = Array.isArray(eventObj[constants_1.EXDATE_KEY])
                    ? __spreadArray(__spreadArray([], eventObj[constants_1.EXDATE_KEY], true), [value], false) : [value];
            }
        }
        else {
            eventObj[key] = value;
        }
    }
    return eventObj;
};
exports.formatStringToKeyValueObj = formatStringToKeyValueObj;
var getResult = function (rawString) {
    var eventObj = {};
    // extract VALARMS from string
    var _a = (0, utils_1.extractProperty)(rawString, 'VALARM'), mainProperty = _a.mainProperty, extractedProperty = _a.extractedProperty;
    var alarmsString = extractedProperty;
    // Format event string, merge multiline values
    (0, exports.formatStringToKeyValueObj)(mainProperty, eventObj);
    // format alarms
    if (alarmsString && alarmsString.length > 0) {
        eventObj.alarms = [];
        var alarmStrings = (0, utils_1.splitDataSetsByKey)(alarmsString, 'VALARM');
        alarmStrings.forEach(function (item) {
            var alarmObj = {};
            (0, exports.formatStringToKeyValueObj)(item, alarmObj);
            var cleanedAlarm = (0, utils_2.cleanAlarmObj)(alarmObj);
            if (cleanedAlarm) {
                eventObj.alarms.push(cleanedAlarm);
            }
        });
    }
    return eventObj;
};
var getOneEventJSON = function (rawString) {
    return getResult(rawString);
};
var getOneTodoJSON = function (rawString) {
    return getResult(rawString);
};
/**
 * Split string to separate key and value
 * @param item
 */
var splitRowToKeyValueObj = function (item) {
    // Get basic delimiter indexes
    var basicDelimiterIndex = item.indexOf(':');
    var nestedDelimiterIndex = item.indexOf(';');
    // Check if item has nested values
    var hasNestedValues = nestedDelimiterIndex !== -1 && nestedDelimiterIndex < basicDelimiterIndex;
    var key;
    var value;
    // Set keys first
    if (hasNestedValues &&
        item.slice(0, nestedDelimiterIndex) !== constants_1.RRULE_ICAL_KEY) {
        key = (0, formatHelpers_1.normalizeKey)(item.slice(0, nestedDelimiterIndex));
    }
    else {
        key = (0, formatHelpers_1.normalizeKey)(item.slice(0, basicDelimiterIndex));
    }
    // Check if key is date parameter
    var isDateKey = (0, common_1.checkIfIsDateKey)(key);
    // Set values
    if (hasNestedValues && constants_1.ALWAYS_STRING_VALUES.indexOf(key) !== -1) {
        // Should format nested values summary, location and description to simple
        // string
        value = (0, formatHelpers_1.extractAlwaysStringValue)(item);
    }
    else if (hasNestedValues && key !== constants_1.RRULE_KEY) {
        value = isDateKey
            ? (0, formatHelpers_1.normalizeString)(item.slice(nestedDelimiterIndex + 1))
            : parseNestedValues(item.slice(nestedDelimiterIndex + 1));
    }
    else {
        value = (0, formatHelpers_1.normalizeString)(item.slice(basicDelimiterIndex + 1));
    }
    if (isDateKey) {
        if ((0, utils_1.isExDateArray)(key, value)) {
            var arrayValues = (0, utils_1.removeSpaceAndNewLine)(value).split(',');
            value = arrayValues.map(function (value) { return (0, dateHelpers_1.parseICalDate)((0, dateHelpers_1.removeArtifacts)(value)); });
        }
        else {
            value = (0, dateHelpers_1.parseICalDate)((0, dateHelpers_1.removeArtifacts)(value));
        }
    }
    // UID cant have any space between chars
    if (key === constants_1.UID_KEY) {
        value = value.replace(' ', '');
    }
    if (key === constants_1.RRULE_KEY) {
        value = (0, dateHelpers_1.removeArtifacts)(value);
    }
    return {
        key: key,
        value: value,
    };
};
/**
 * Split item to key and nested values
 * @param item
 */
var splitNestedValues = function (item) {
    var nestedValueDelimiterIndex = item.indexOf('=');
    return {
        key: (0, formatHelpers_1.normalizeKey)(item.slice(0, nestedValueDelimiterIndex)),
        value: item.slice(nestedValueDelimiterIndex + 1),
    };
};
/**
 * Split values to nested obj, except date key
 * @param values
 */
var parseNestedValues = function (values) {
    var result = {};
    // Separate key values with ; delimiter
    var valuesArray = values.split(';');
    for (var _i = 0, valuesArray_1 = valuesArray; _i < valuesArray_1.length; _i++) {
        var item = valuesArray_1[_i];
        var keyValue = splitNestedValues(item);
        var key = keyValue.key, value = keyValue.value;
        // ** Handle exception with date in nested value ** //
        // f.e. date without time
        if (key === 'value' && value.indexOf('DATE') !== -1) {
            result = (0, formatHelpers_1.normalizeString)(value.slice(value.indexOf('DATE')));
        }
        else if (value.indexOf(constants_1.MAILTO_KEY_WITH_DELIMITER) !== -1) {
            result[key.toUpperCase()] = (0, formatHelpers_1.normalizeString)(value
                .slice(0, value.indexOf(constants_1.MAILTO_KEY_WITH_DELIMITER))
                .replace(' ', ''));
            result[constants_1.MAILTO_KEY] = (0, formatHelpers_1.normalizeString)(value.slice(value.indexOf("".concat(constants_1.MAILTO_KEY_WITH_DELIMITER, ":")) +
                "".concat(constants_1.MAILTO_KEY_WITH_DELIMITER, ":").length));
        }
        else {
            result[key.toUpperCase()] = (0, formatHelpers_1.normalizeString)(value);
        }
    }
    return result;
};
/**
 * Main function
 * Get key values from each line to build obj
 * @param iCalStringEvent
 */
var toJSON = function (iCalStringEvent) {
    var errors = [];
    var events = [];
    var todos = [];
    var calendar;
    try {
        // Validate string
        (0, validator_1.validateICalString)(iCalStringEvent);
    }
    catch (e) {
        errors.push(e.message);
        return {
            calendar: {
                begin: '',
                prodid: '',
                version: '',
                end: '',
            },
            events: events,
            todos: todos,
            errors: errors,
        };
    }
    // Get vcalendar props
    var vCalendarString = getVCalendarString(iCalStringEvent);
    calendar = formatVCalendarStringToObject(vCalendarString);
    // Get base content
    var baseCalendarContent = iCalStringEvent.slice(vCalendarString.length, iCalStringEvent.length - constants_1.CALENDAR_END_KEY_VALUE.length);
    // Extract vtodos
    var _a = (0, utils_1.extractProperty)(baseCalendarContent, 'VTODO'), mainProperty = _a.mainProperty, extractedProperty = _a.extractedProperty;
    var eventsString = mainProperty;
    var todosString = extractedProperty;
    // Remove not supported properties
    var stringCleaned = (0, utils_1.removeProperty)(eventsString, 'DAYLIGHT');
    stringCleaned = (0, utils_1.removeProperty)(stringCleaned, 'VTIMEZONE');
    stringCleaned = (0, utils_1.removeProperty)(stringCleaned, 'STANDARD');
    // Split string events to array
    var vEventsArray = splitStringEvents(stringCleaned);
    // Split string todos to array
    var vTodosArray = splitStringEvents(todosString, constants_1.TODO_BEGIN_KEY_VALUE);
    // Parse each event to obj
    vEventsArray.forEach(function (stringEvent) {
        try {
            var event_1 = getOneEventJSON(stringEvent);
            events.push(event_1);
        }
        catch (e) {
            errors.push(e.message);
        }
    });
    vTodosArray.forEach(function (stringTodo) {
        try {
            var todo = getOneTodoJSON(stringTodo);
            todos.push(todo);
        }
        catch (e) {
            errors.push(e.message);
        }
    });
    return {
        calendar: calendar,
        events: events,
        todos: todos,
        errors: errors,
    };
};
exports.default = toJSON;
