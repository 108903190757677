"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanAlarmObj = exports.formatExDatesToString = exports.formatAlarmsToString = exports.mapAlarmObjToString = exports.mapObjToString = void 0;
var index_1 = require("./index");
var common_1 = require("../common");
var mapObjToString = function (obj) {
    var result = '';
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        result = result + key.toUpperCase() + '=' + value + '\n';
    }
    return result;
};
exports.mapObjToString = mapObjToString;
var mapAlarmObjToString = function (obj) {
    var result = '';
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        result = result + (0, index_1.transformToICalKey)(key) + ':' + value + '\n';
    }
    return result;
};
exports.mapAlarmObjToString = mapAlarmObjToString;
var formatAlarmsToString = function (alarms) {
    var result = '';
    alarms.forEach(function (item) {
        result += 'BEGIN:VALARM\n';
        result += (0, index_1.foldLine)((0, exports.mapAlarmObjToString)(item));
        result += 'END:VALARM\n';
    });
    return result;
};
exports.formatAlarmsToString = formatAlarmsToString;
var formatExDatesToString = function (exDates) {
    var result = '';
    exDates.forEach(function (item) {
        var _a;
        var hasTimezone = (item === null || item === void 0 ? void 0 : item.timezone) !== undefined;
        var isSimpleObj = !hasTimezone && (item === null || item === void 0 ? void 0 : item.value) !== undefined;
        var isSimpleDate = !hasTimezone && isSimpleObj && ((_a = item.value) === null || _a === void 0 ? void 0 : _a.length) === common_1.DATE_ONLY_LENGTH;
        var delimiter = ';';
        if (isSimpleDate) {
            // Date only for all day events
            result +=
                (0, index_1.foldLine)("".concat((0, index_1.transformToICalKey)('exdate'), ";VALUE=DATE:").concat(item.value)) +
                    '\n';
        }
        else if (isSimpleObj) {
            delimiter = ':';
            result +=
                (0, index_1.foldLine)("".concat((0, index_1.transformToICalKey)('exdate')).concat(delimiter).concat((0, index_1.parseUtcDateObj)(item))) + '\n';
        }
        else if (hasTimezone) {
            delimiter = ';';
            // Object with timezone and value
            result +=
                (0, index_1.foldLine)("".concat((0, index_1.transformToICalKey)('exdate')).concat(delimiter).concat((0, index_1.parseDateWithTimezone)(item))) + '\n';
        }
        else {
            delimiter = ':';
            result +=
                (0, index_1.foldLine)("".concat((0, index_1.transformToICalKey)('exdate')).concat(delimiter).concat((0, index_1.parseUtcToTimestamp)(item))) + '\n';
        }
    });
    return result;
};
exports.formatExDatesToString = formatExDatesToString;
var cleanAlarmObj = function (alarm) {
    var _a, _b, _c, _d;
    delete alarm.valar;
    delete alarm.begin;
    delete alarm.end;
    // not supported
    if ((_a = alarm.trigger) === null || _a === void 0 ? void 0 : _a.RELATED) {
        return null;
    }
    if ((_b = alarm.trigger) === null || _b === void 0 ? void 0 : _b.VALUE) {
        var parsedValue = alarm.trigger.VALUE;
        if (parsedValue.indexOf('DURATION:') !== -1) {
            parsedValue = parsedValue.slice('DURATION:'.length);
        }
        alarm.trigger = parsedValue;
    }
    // not supported
    if (((_c = alarm.trigger) === null || _c === void 0 ? void 0 : _c.indexOf('DATE-TIME')) !== -1) {
        return null;
    }
    if (((_d = alarm.trigger) === null || _d === void 0 ? void 0 : _d.indexOf('DT')) !== -1) {
        return null;
    }
    return alarm;
};
exports.cleanAlarmObj = cleanAlarmObj;
