"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WRONG_FORMAT_ERROR = exports.INVALID_DATE_ERROR = exports.UID_KEY = exports.ALARMS_KEY = exports.ORGANIZER_KEY = exports.ATTENDEE_KEY = exports.EXDATE_KEY = exports.RRULE_ICAL_KEY = exports.RRULE_KEY = exports.MAILTO_KEY = exports.MAILTO_KEY_WITH_DELIMITER = exports.ALWAYS_STRING_VALUES = exports.RECURSION_MAX_COUNT = exports.TODO_END_KEY_VALUE = exports.TODO_BEGIN_KEY_VALUE = exports.CALENDAR_END_KEY_VALUE = exports.CALENDAR_BEGIN_KEY_VALUE = exports.EVENT_END_KEY_VALUE = exports.EVENT_BEGIN_KEY_VALUE = void 0;
exports.EVENT_BEGIN_KEY_VALUE = 'BEGIN:VEVENT';
exports.EVENT_END_KEY_VALUE = 'END:VEVENT';
exports.CALENDAR_BEGIN_KEY_VALUE = 'BEGIN:VCALENDAR';
exports.CALENDAR_END_KEY_VALUE = 'END:VCALENDAR';
exports.TODO_BEGIN_KEY_VALUE = 'BEGIN:VTODO';
exports.TODO_END_KEY_VALUE = 'END:VTODO';
exports.RECURSION_MAX_COUNT = 5000;
exports.ALWAYS_STRING_VALUES = [
    'summary',
    'description',
    'location',
];
exports.MAILTO_KEY_WITH_DELIMITER = ':mailto';
exports.MAILTO_KEY = 'mailto';
exports.RRULE_KEY = 'rrule';
exports.RRULE_ICAL_KEY = 'RRULE';
exports.EXDATE_KEY = 'exdate';
exports.ATTENDEE_KEY = 'attendee';
exports.ORGANIZER_KEY = 'organizer';
exports.ALARMS_KEY = 'alarms';
exports.UID_KEY = 'uid';
exports.INVALID_DATE_ERROR = 'Date is not valid';
exports.WRONG_FORMAT_ERROR = 'Wrong format';
