"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isExDateArray = exports.removeSpaceAndNewLine = exports.splitDataSetsByKey = exports.extractProperty = exports.removeProperty = void 0;
var constants_1 = require("../constants");
/**
 * Remove unwanted properties in recursion
 * @param vEventsString
 * @param property
 * @param count
 */
var removeProperty = function (vEventsString, property, count) {
    if (count === void 0) { count = constants_1.RECURSION_MAX_COUNT; }
    var eventStringResult = vEventsString;
    var indexOfBeginProperty = eventStringResult.indexOf("BEGIN:".concat(property));
    var indexOfEndProperty = eventStringResult.indexOf("END:".concat(property));
    if (indexOfBeginProperty !== -1 && count > 0) {
        eventStringResult =
            eventStringResult.slice(0, indexOfBeginProperty) +
                eventStringResult.slice(indexOfEndProperty + "END:".concat(property).length);
        return (0, exports.removeProperty)(eventStringResult, property, count - 1);
    }
    else {
        return eventStringResult;
    }
};
exports.removeProperty = removeProperty;
var extractProperty = function (vEventsString, property, count, result) {
    if (count === void 0) { count = constants_1.RECURSION_MAX_COUNT; }
    var resultValue = {
        extractedProperty: (result === null || result === void 0 ? void 0 : result.extractedProperty) || '',
        mainProperty: vEventsString,
    };
    var eventStringResult = (result === null || result === void 0 ? void 0 : result.mainProperty) || vEventsString;
    var indexOfBeginProperty = eventStringResult.indexOf("BEGIN:".concat(property));
    var indexOfEndProperty = eventStringResult.indexOf("END:".concat(property));
    if (indexOfBeginProperty !== -1 && count > 0) {
        resultValue.extractedProperty =
            (resultValue === null || resultValue === void 0 ? void 0 : resultValue.extractedProperty) +
                eventStringResult.slice(indexOfBeginProperty, indexOfEndProperty);
        eventStringResult =
            eventStringResult.slice(0, indexOfBeginProperty) +
                eventStringResult.slice(indexOfEndProperty + "END:".concat(property).length);
        resultValue.mainProperty = eventStringResult;
        return (0, exports.extractProperty)(eventStringResult, property, count - 1, resultValue);
    }
    else {
        return resultValue;
    }
};
exports.extractProperty = extractProperty;
/**
 * Split string data sets to array
 * @param iCalEvents
 */
var splitDataSetsByKey = function (stringData, key) {
    // Get array of events
    var result = stringData.split(key).slice(1);
    if (!result) {
        return '';
    }
    // Add missing delimiter from split to each record
    result = result.map(function (item) { return "".concat(key).concat(item); });
    return result;
};
exports.splitDataSetsByKey = splitDataSetsByKey;
var removeSpaceAndNewLine = function (value, counter) {
    if (counter === void 0) { counter = 0; }
    var hasSpace = value.indexOf(' ') !== -1;
    var hasNewLine = value.indexOf('\n') !== -1;
    if (counter > 1000) {
        return value;
    }
    if (!hasSpace && !hasNewLine) {
        return value;
    }
    var result = value;
    if (hasSpace) {
        result = result.replace(' ', '');
    }
    if (hasNewLine) {
        result = result.replace('\n', '');
    }
    return (0, exports.removeSpaceAndNewLine)(result, counter + 1);
};
exports.removeSpaceAndNewLine = removeSpaceAndNewLine;
var isExDateArray = function (key, value) {
    if (key === constants_1.EXDATE_KEY && value.indexOf(',') !== -1) {
        return true;
    }
    return false;
};
exports.isExDateArray = isExDateArray;
