"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIfIsDateKeyToString = exports.checkIfIsDateKey = exports.DATE_ONLY_LENGTH = exports.DATE_KEYS_TO_STRING = exports.DATE_KEYS = exports.MAX_LINE_LENGTH = void 0;
exports.MAX_LINE_LENGTH = 75;
exports.DATE_KEYS = [
    'dtstart',
    'dtend',
    'dtstamp',
    'created',
    'lastModified',
    'exdate',
    'recurrenceId',
];
exports.DATE_KEYS_TO_STRING = [
    'dtstart',
    'dtend',
    'dtstamp',
    'created',
    'lastModified',
    'recurrenceId',
    'exdate',
];
exports.DATE_ONLY_LENGTH = 8;
var checkIfIsDateKey = function (keyValueString) {
    return exports.DATE_KEYS.indexOf(keyValueString) !== -1;
};
exports.checkIfIsDateKey = checkIfIsDateKey;
var checkIfIsDateKeyToString = function (keyValueString) {
    return exports.DATE_KEYS_TO_STRING.indexOf(keyValueString) !== -1;
};
exports.checkIfIsDateKeyToString = checkIfIsDateKeyToString;
