"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateICalString = exports.validateStringDateWithoutTime = exports.validateISOStringDate = void 0;
var luxon_1 = require("luxon");
var constants_1 = require("../constants");
var enums_1 = require("../enums");
var validateISOStringDate = function (stringDate) {
    if (!luxon_1.DateTime.fromISO(stringDate).isValid) {
        throw new Error("".concat(enums_1.ERROR_MSG.INVALID_DATE, ": ").concat(stringDate));
    }
};
exports.validateISOStringDate = validateISOStringDate;
var validateStringDateWithoutTime = function (stringDate) {
    if (!luxon_1.DateTime.fromFormat(stringDate, 'yyyyMMdd').isValid) {
        throw new Error("".concat(enums_1.ERROR_MSG.INVALID_DATE, ": ").concat(stringDate));
    }
};
exports.validateStringDateWithoutTime = validateStringDateWithoutTime;
/**
 * Check some basic properties of string Ical
 * @param iCalString
 */
var validateICalString = function (iCalString) {
    if ((iCalString.indexOf(constants_1.EVENT_BEGIN_KEY_VALUE) === -1 ||
        iCalString.indexOf(constants_1.EVENT_END_KEY_VALUE) === -1) &&
        (iCalString.indexOf(constants_1.TODO_BEGIN_KEY_VALUE) === -1 ||
            iCalString.indexOf(constants_1.TODO_END_KEY_VALUE) === -1)) {
        throw new Error(enums_1.ERROR_MSG.WRONG_FORMAT);
    }
    if (iCalString.indexOf(constants_1.CALENDAR_BEGIN_KEY_VALUE) === -1 ||
        iCalString.indexOf(constants_1.CALENDAR_END_KEY_VALUE) === -1) {
        throw new Error(enums_1.ERROR_MSG.WRONG_FORMAT);
    }
};
exports.validateICalString = validateICalString;
