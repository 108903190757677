"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICalDate = exports.removeArtifacts = exports.getBaseDate = exports.formatTzidDate = exports.formatToIsoDate = void 0;
var timezoneParser_1 = require("./timezoneParser");
var luxon_1 = require("luxon");
/**
 * Format to ISO date
 * @param date
 */
var formatToIsoDate = function (date) {
    var baseDate = (0, exports.getBaseDate)(date);
    var year = baseDate.slice(0, 4);
    var month = baseDate.slice(4, 6);
    var day = baseDate.slice(6, 8);
    var hour = baseDate.slice(8, 10);
    var minute = baseDate.slice(10, 12);
    var result = "".concat(year).concat(month).concat(day, "T").concat(hour).concat(minute, "00Z");
    // validateISOStringDate(result);
    return result;
};
exports.formatToIsoDate = formatToIsoDate;
var formatTzidDate = function (date, baseString) {
    var baseDate = baseString ? baseString : (0, exports.getBaseDate)(date);
    var year = baseDate.slice(0, 4);
    var month = baseDate.slice(4, 6);
    var day = baseDate.slice(6, 8);
    var hour = baseDate.slice(8, 10);
    var minute = baseDate.slice(10, 12);
    var seconds = baseDate.slice(12, 14);
    var result = "".concat(year).concat(month).concat(day, "T").concat(hour).concat(minute).concat(seconds);
    return result;
};
exports.formatTzidDate = formatTzidDate;
var getBaseDate = function (date) {
    if (!date) {
        throw Error("No date: ".concat(date));
    }
    var result = date.replace('T', '');
    result = result.replace('Z', '');
    if (Number.isNaN(result)) {
        throw Error("Invalid date: ".concat(date));
    }
    if (result.length !== '20210401130000'.length) {
        if (result.length < '20210401130000'.length) {
            throw Error("Invalid date: ".concat(date));
        }
        if (result.length !== '2021040113000000'.length) {
            throw Error("Invalid date: ".concat(date));
        }
        else {
            return result.slice(0, -2);
        }
    }
    return result;
};
exports.getBaseDate = getBaseDate;
var removeArtifacts = function (value, counter) {
    if (counter === void 0) { counter = 0; }
    if (counter > 30) {
        return value;
    }
    var newValue = value;
    var hasSlashN = value.indexOf('\n') !== -1;
    var hasSlashR = value.indexOf('\r') !== -1;
    if (hasSlashN) {
        newValue = newValue.replace('\n', '');
    }
    if (hasSlashR) {
        newValue = newValue.replace('\r', '');
    }
    return (0, exports.removeArtifacts)(newValue, counter + 1);
};
exports.removeArtifacts = removeArtifacts;
/**
 * Better formatting for dates
 * @param iCalDate
 */
var parseICalDate = function (iCalDate) {
    var _a, _b;
    // No special handling for other dates
    var isTzidDate = iCalDate.indexOf('TZID') !== -1;
    var isAllDayEvent = iCalDate.indexOf('DATE:') !== -1;
    var isSimpleDate = !isTzidDate && !isAllDayEvent;
    if (isSimpleDate) {
        var value = (0, exports.formatToIsoDate)(iCalDate);
        if (!luxon_1.DateTime.fromISO(value).isValid) {
            throw Error("Invalid date: ".concat(value));
        }
        return { value: (0, exports.formatToIsoDate)(iCalDate) };
    }
    if (isAllDayEvent) {
        var baseDate = iCalDate.slice(iCalDate.indexOf('DATE:') + 'DATE:'.length);
        var year = baseDate.slice(0, 4);
        var month = baseDate.slice(4, 6);
        var day = baseDate.slice(6, 8);
        var dateString = "".concat(year).concat(month).concat(day);
        if (!dateString ||
            dateString === '' ||
            dateString.indexOf('Invalid') !== -1) {
            throw Error("Cannot parse date: ".concat(baseDate));
        }
        if (!luxon_1.DateTime.fromFormat(dateString, 'yyyyLLdd').isValid) {
            throw Error("Invalid date: ".concat(dateString));
        }
        return { value: dateString, isAllDay: true };
    }
    // Need to format tzid date value to UTC
    if (isTzidDate) {
        var timezone = (_a = iCalDate === null || iCalDate === void 0 ? void 0 : iCalDate.split(':')) === null || _a === void 0 ? void 0 : _a[0];
        var timezoneParsed = (0, timezoneParser_1.timezoneParser)(timezone === null || timezone === void 0 ? void 0 : timezone.slice((timezone === null || timezone === void 0 ? void 0 : timezone.indexOf('TZID=')) + 'TZID='.length));
        var dateExtracted = (_b = iCalDate === null || iCalDate === void 0 ? void 0 : iCalDate.split(':')) === null || _b === void 0 ? void 0 : _b[1];
        var baseDate = (0, exports.getBaseDate)(dateExtracted);
        var resultDate = (0, exports.formatTzidDate)(dateExtracted, baseDate);
        var zuluDate = luxon_1.DateTime.fromFormat(baseDate, 'yyyyLLddHHmmss', {
            zone: timezoneParsed,
        }).toUTC();
        if (zuluDate.invalidReason === 'unsupported zone') {
            throw Error("".concat(zuluDate === null || zuluDate === void 0 ? void 0 : zuluDate.invalidReason, " ").concat(timezoneParsed));
        }
        if (!zuluDate ||
            !zuluDate.isValid ||
            zuluDate.toString().indexOf('Invalid') !== -1) {
            throw Error("Cannot parse date: ".concat(dateExtracted));
        }
        return {
            value: resultDate,
            timezone: timezoneParsed,
        };
    }
    return { value: (0, exports.formatToIsoDate)(iCalDate) };
};
exports.parseICalDate = parseICalDate;
